import Vue from 'vue'
import Vuex from 'vuex'
import $common from '@/utils/common.js'
import common from './modules/common'
import user from './modules/user'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
	  // httpUrl: "http://127.0.0.1:8080/supervisor-api",
    // httpUrl: "http://39.99.146.123:8081/supervisor-api",
    httpUrl: "https://cs.ccsi.com.cn/supervisor-api",
	  pageSize: 10,
      showTestFlag: false
  },
  mutations: {
	  // 重置vuex本地储存状态
	  // resetStore (state) {
	  //   Object.keys(state).forEach((key) => {
	  //     state[key] = cloneDeep(window.SITE_CONFIG['storeState'][key])
	  //   })
	  // }
  },
  actions: {
  },
  modules: {
    common,
    user
  },
})
