/**
 * 全站路由配置
 *
 * 建议:
 * 1. 代码中路由统一使用name属性跳转(不使用path属性)
 */
import Vue from 'vue'
import Router from 'vue-router'
import $store from '@/store/index'
import http from '@/utils/httpRequest'
import {isURL} from '@/utils/validate'
// import {clearLoginInfo} from '@/utils'
import $common from '@/utils/common'
import $userInfo from '@/utils/userInfo'

Vue.use(Router)

// 开发环境不使用懒加载, 因为懒加载页面太多的话会造成webpack热更新太慢, 所以只有生产环境使用懒加载
const _import = require('./import-' + process.env.NODE_ENV)

// 全局路由(无需嵌套上左右整体布局)
const globalRoutes = [
  {path: '/404', component: _import('common/404'), name: '404', meta: {title: '404未找到'}},
  {path: '/login', component: _import('common/login'), name: 'login', meta: {title: '登录'}},
  {path: '/fileDirectory', component: _import('dataFile/fileDirectory'), name: 'fileDirectory', meta: {title: '查看文件目录'}},
  {path: '/finishedFile', component: _import('dataFile/finishedFile'), name: 'finishedFile', meta: {title: '完工资料'}},
  {path: '/projectlist', component: _import('modules/projectInfo/projectlist'), name: 'projectlist', meta: {title: '项目列表'}},
]

// 主入口路由(需嵌套上左右整体布局)
const mainRoutes = {
  path: '/',
  component: _import('main'),
  name: 'main',
  redirect: {name: 'workbench'},
  meta: {title: '主入口整体布局'},
  children: [
    // 通过meta对象设置路由展示方式
    // 1. isTab: 是否通过tab展示内容, true: 是, false: 否
    // 2. iframeUrl: 是否通过iframe嵌套展示内容, '以http[s]://开头': 是, '': 否
    // 提示: 如需要通过iframe嵌套展示内容, 但不通过tab打开, 请自行创建组件使用iframe处理!
    {path: '/home', component: _import('common/home'), name: 'home', meta: {title: '首页'}},
    {path: '/theme', component: _import('common/theme'), name: 'theme', meta: {title: '主题'}},
	// 临时路由
	{path: '/workbench', component: _import('modules/projectInfo/workbench'), name: 'workbench', meta: {title: '项目工作台'}},
	{path: '/subjectList', component: _import('modules/projectInfo/subjectList'), name: 'subjectList', meta: {title: '项目标的物'}},
	{path: '/nodeList', component: _import('modules/projectInfo/nodeList'), name: 'nodeList', meta: {title: '标的物节点信息'}},
	{path: '/finishedFileList', component: _import('modules/finishfile/finishedFileList'), name: 'finishedFileList', meta: {title: '完工资料'}},
	{path: '/patrolList', component: _import('modules/projectPatrol/patrolList'), name: 'patrolList', meta: {title: '巡视信息'}},
	{path: '/testItem', component: _import('modules/projectTest/testItem'), name: 'testItem', meta: {title: '报验项目'}},
    {path: '/applyList', component: _import('modules/projectTest/applyList'), name: 'applyList', meta: {title: '报验申请'}},
    {path: '/information', component: _import('modules/projectTest/information'), name: 'information', meta: {title: '报验信息'}},
    // {path: '/informationInfo', component: _import('modules/projectTest/information-info'), name: 'informationInfo', meta: {title: '查看报验信息'}},
    {path: '/summary', component: _import('modules/projectTest/summary'), name: 'summary', meta: {title: '报验汇总'}},
	{path: '/problem', component: _import('modules/problemOpi/problem'), name: 'problem', meta: {title: '问题意见清单'}},
    {path: '/problemInfo', component: _import('modules/problemOpi/problemInfo'), name: 'problemInfo', meta: {title: '问题意见清单'}},
	{path: '/problemAll', component: _import('modules/problemOpi/problemAll'), name: 'problemAll', meta: {title: '问题意见汇总'}},
	{path: '/filesList', component: _import('modules/projectInfoManage/filesList'), name: 'filesList', meta: {title: '文件清单'}},
    {path: '/superdocument', component: _import('modules/projectInfoManage/superdocument'), name: 'superdocument', meta: {title: '监理发文'}},
     {path: '/contactdocument', component: _import('modules/projectInfoManage/contactdocument'), name: 'contactdocument', meta: {title: '监理文件'}},
    {path: '/receipt', component: _import('modules/projectInfoManage/receipt'), name: 'receipt', meta: {title: '监理收文'}},
    {path: '/weekly', component: _import('modules/projectInfoManage/weekly'), name: 'weekly', meta: {title: '监理周报'}},
	{path: '/weeklyExportInfo', component: _import('modules/projectInfoManage/weeklyExportInfo'), name: 'weeklyExportInfo', meta: {title: '导出周报'}},
    {path: '/weeklyDeviceExportInfo', component: _import('modules/projectInfoManage/weeklyDeviceExportInfo'), name: 'weeklyDeviceExportInfo', meta: {title: '导出周报'}},
    {path: '/monthlyExportInfo', component: _import('modules/projectInfoManage/monthlyExportInfo'), name: 'monthlyExportInfo', meta: {title: '导出周报'}},
    {path: '/monthly', component: _import('modules/projectInfoManage/monthly'), name: 'monthly', meta: {title: '监理月报'}},
    {path: '/drawing', component: _import('modules/projectInfoManage/drawing'), name: 'drawing', meta: {title: '图纸'}},
	{path: '/noteList', component: _import('modules/notepad/noteList'), name: 'noteList', meta: {title: '记事信息'}},
	{path: '/meetingList', component: _import('modules/meeting/meetingList'), name: 'meetingList', meta: {title: '会议记录'}},
    {path: '/ownerContract', component: _import('modules/contractManage/ownerContract'), name: 'ownerContract', meta: {title: '业主合同管理'}},
    {path: '/ownerContractInfo', component: _import('modules/contractManage/ownerContractInfo'), name: 'ownerContractInfo', meta: {title: '合同详情'}},
	{path: '/paymentList', component: _import('modules/contractManage/paymentList'), name: 'paymentList', meta: {title: '收款记录'}},
	{path: '/changedList', component: _import('modules/contractManage/changedList'), name: 'changedList', meta: {title: '变更记录'}},
    {path: '/personnelCommun', component: _import('modules/communication/personnelCommun'), name: 'personnelCommun', meta: {title: '人员通讯'}},
	{path: '/file', component: _import('modules/working/file'), name: 'file', meta: {title: '工作文件'}},
    {path: '/examination', component: _import('modules/working/examination'), name: 'examination', meta: {title: '文件审查'}},
    {path: '/workfile', component: _import('modules/working/workfile'), name: 'examination', meta: {title: '监理文件'}},
	{path: '/attendance', component: _import('modules/attendance/attendance'), name: 'attendance', meta: {title: '考勤记录'}},
	{path: '/overtime', component: _import('modules/attendance/overtime'), name: 'overtime', meta: {title: '加班记录'}},
	{path: '/leave', component: _import('modules/attendance/leave'), name: 'leave', meta: {title: '请假记录'}},
    {path: '/vacation', component: _import('modules/attendance/vacation'), name: 'leave', meta: {title: '休假记录'}},
	{path: '/customer', component: _import('modules/addressBook/customer'), name: 'customer', meta: {title: '人员通讯'}},
	{path: '/enterprise', component: _import('modules/addressBook/enterprise'), name: 'enterprise', meta: {title: '企业信息'}},
	{path: '/stationList', component: _import('modules/sideStation/stationList'), name: 'stationList', meta: {title: '旁站信息'}},
	{path: '/controlFiles', component: _import('modules/controlFiles/controlFiles'), name: 'controlFiles', meta: {title: '投资控制文件'}},
    {path: '/projectMonitorList', component: _import('modules/projectInfo/projectMonitorList'), name: 'projectMonitorList', meta: {title: '监控清单'}},
    {path: '/projectImportantList', component: _import('modules/projectInfo/projectImportantList'), name: 'projectMonitorList', meta: {title: '重要事项'}},
    {path: '/qualitySituationList', component: _import('modules/quality/qualitySituationList'), name: 'projectMonitorList', meta: {title: '质量情况信息'}},
    {path: '/detailedProgressList', component: _import('modules/progress/detailedProgressList'), name: 'projectMonitorList', meta: {title: '详细进度'}},
    {path: '/shipProgressList', component: _import('modules/progress/shipProgressList'), name: 'projectMonitorList', meta: {title: '结构进度'}},
    {path: '/qualityProgressPhotoList', component: _import('modules/quality/qualityProgressPhotoList'), name: 'projectMonitorList', meta: {title: '质量进度照片'}},
    {path: '/coordinateList', component: _import('modules/working/coordinateList'), name: 'projectMonitorList', meta: {title: '协调记录'}},
    {path: '/mooringTestList', component: _import('modules/progress/mooringTestList'), name: 'projectMonitorList', meta: {title: '系泊试验进度'}},
    {path: '/purchaseDeviceList', component: _import('modules/projectInfo/purchaseDeviceList'), name: 'purchaseDeviceList', meta: {title: '外购件情况'}},
    {path: '/cabinprogressinfo', component: _import('modules/projectInfo/cabinprogressinfo'), name: 'cabinprogressinfo', meta: {title: '油水舱室进度'}},
    {path: '/pipingprogressinfo', component: _import('modules/projectInfo/pipingprogressinfo'), name: 'pipingprogressinfo', meta: {title: '管系进度跟踪'}},
    {path: '/progressSceneRecordList', component: _import('modules/projectInfo/progressSceneRecordList'), name: 'progressSceneRecordList', meta: {title: '现场进度模块'}},
      {path: '/projectNodeList', component: _import('modules/projectInfo/projectNodeList'), name: 'projectNodeList', meta: {title: '项目节点信息'}},
      // {path: '/weekOther', component: _import('modules/weekOther/weekOther'), name: 'weekOther', meta: {title: '周报赋值数据录入'}},
      {path: '/workloginfo', component: _import('modules/projectInfoManage/workloginfo'), name: 'workloginfo', meta: {title: '监理日志'}},
      {path: '/majorList', component: _import('modules/projectInfoManage/majorList'), name: 'majorList', meta: {title: '专业管理'}},
      {path: '/workfilesetting', component: _import('modules/working/workfilesetting'), name: 'workfilesetting', meta: {title: '监理文件类型设置'}},
      {path: '/examinationfilesetting', component: _import('modules/working/examinationfilesetting'), name: 'examinationfilesetting', meta: {title: '文件审查类型设置'}},
      {path: '/remainingProblems', component: _import('modules/working/remainingProblems'), name: 'remainingProblems', meta: {title: '项目遗留问题汇总'}},
      {path: '/fatFileList', component: _import('modules/fatfile/fatFileList'), name: 'fatFileList', meta: {title: 'FAT文件'}},
      {path: '/specialreport', component: _import('modules/specialreport/specialreportList'), name: 'specialreport', meta: {title: '专题报告'}},
      {path: '/workLogInfoUpdate', component: _import('modules/projectInfoManage/workloginfo-update'), name: 'workLogInfoUpdate', meta: {title: '监理日志修改'}},
      {path: '/projectchange', component: _import('modules/contractManage/projectchange'), name: 'projectchange', meta: {title: '工程变更信息'}},
      {path: '/progressSettingList', component: _import('modules/progress/progressSettingList'), name: 'progressSettingList', meta: {title: '船舶监理进度设置'}},
      {path: '/progressList', component: _import('modules/progress/progressList'), name: 'progressList', meta: {title: '船舶进度控制'}},
      {path: '/projectSceneSettingList', component: _import('modules/progress/projectSceneSettingList'), name: 'projectSceneSettingList', meta: {title: '工程现场进度设置'}},
      {path: '/projectSceneRecordList', component: _import('modules/progress/projectSceneRecordList'), name: 'projectSceneRecordList', meta: {title: '工程现场进度情况'}},
      {path: '/projectProgressNodeSettingList', component: _import('modules/progress/projectProgressNodeSettingList'), name: 'projectProgressNodeSettingList', meta: {title: '工程进度节点设置'}},
      {path: '/projectProgressRecordList', component: _import('modules/progress/projectProgressRecordList'), name: 'projectProgressRecordList', meta: {title: '工程节点进度情况'}},
      {path: '/projectWeekly', component: _import('modules/projectInfoManage/projectWeekly'), name: 'projectWeekly', meta: {title: '监理新周报'}},
      {path: '/projectWeeklyExportInfo', component: _import('modules/projectInfoManage/projectWeeklyExportInfo'), name: 'projectWeeklyExportInfo', meta: {title: '新周报'}},
      {path: '/viewPdf', component: _import('modules/projectInfo/viewPdf'), name: 'viewPdf', meta: {title: 'PDF预览'}},
      {path: '/contractDurationList', component: _import('modules/contractduration/contractDurationList'), name: 'contractDurationList', meta: {title: '项目合同工期设置'}},
      {path: '/safetysupervisioninspectionList', component: _import('modules/safetysupervisioninspection/safetysupervisioninspectionList'), name: 'safetysupervisioninspectionList', meta: {title: '安全监督检查记录'}},
      {path: '/dailyList', component: _import('modules/daily/dailyList'), name: 'dailyList', meta: {title: '监理日报'}},
      {path: '/webmoduleshow', component: _import('modules/projectInfoManage/webmoduleshow'), name: 'webmoduleshow', meta: {title: '对外门户展示信息设置'}},
      // {path: '/projectKindSetting', component: _import('modules/projectInfo/project-kind-setting'), name: 'projectKindSetting', meta: {title: '项目设置'}},

  ],
  // beforeEnter(to, from, next) {
  //   let token = Vue.cookie.get('token')
  //   if (!token || !/\S/.test(token)) {
  //     clearLoginInfo()
  //     next({name: 'login'})
  //   }
  //   next()
  // }
}

const router = new Router({
  mode: 'hash',
  scrollBehavior: () => ({y: 0}),
  isAddDynamicMenuRoutes: false, // 是否已经添加动态(菜单)路由
  routes: globalRoutes.concat(mainRoutes)
})

router.beforeEach((to, from, next) => {
  // 添加动态(菜单)路由
  // 1. 已经添加 or 全局路由, 直接访问
  // 2. 获取菜单列表, 添加并保存本地存储
  // if (router.options.isAddDynamicMenuRoutes || fnCurrentRouteType(to, globalRoutes) === 'global') {
  //   next()
  // } else {
  //   http({
  //     url: $store.state.httpUrl + '/sys/menu/nav',
  //     method: 'get',
  //     params: http.adornParams()
  //   }).then(({data}) => {
  //     if (data && data.code === 0) {
  //       fnAddDynamicMenuRoutes(data.menuList)
  //       router.options.isAddDynamicMenuRoutes = true
  //       sessionStorage.setItem('menuList', JSON.stringify(data.menuList || '[]'))
  //       sessionStorage.setItem('permissions', JSON.stringify(data.permissions || '[]'))
  //       next({...to, replace: true})
  //     } else {
  //       sessionStorage.setItem('menuList', '[]')
  //       sessionStorage.setItem('permissions', '[]')
  //       next()
  //     }
  //   }).catch((e) => {
  //     // console.log(`%c${e} 请求菜单列表和权限失败，跳转至登录页！！`, 'color:blue')
  //     router.push({name: 'login'})
  //   })
  // }
	if(to.path == "/login" || to.path == "/fileDirectory" || to.path == "/finishedFile"){
		next();
	}else{
		// 联合登录-地址栏获取ATOKEN
		if(to.query.appId != undefined && to.query.appId != ''
			&& to.query.secretKey != undefined && to.query.secretKey != ''){
			$common.setItem('appId',to.query.appId).then((res)=>{
				$common.setItem('secretKey',to.query.secretKey).then((res)=>{
					$userInfo.getUserInfo().then((res)=>{
                      // $userInfo.getSubjectDropDown().then((res)=>{
                          next();
                      // })
					});
				});
			});
		}else{
			// 已登录
			let _ATOKEN = $common.getItem('ATOKEN');
			if(_ATOKEN != undefined && _ATOKEN != null && _ATOKEN != ''){
                // $userInfo.getSubjectDropDown().then((res)=>{
                //     next();
                // })
				next();
			}else{
				// 未登录
				// next("/login");
			}
		}
	}
})

/**
 * 判断当前路由类型, global: 全局路由, main: 主入口路由
 * @param {*} route 当前路由
 */
function fnCurrentRouteType(route, globalRoutes = []) {
  var temp = []
  for (var i = 0; i < globalRoutes.length; i++) {
    if (route.path === globalRoutes[i].path) {
      return 'global'
    } else if (globalRoutes[i].children && globalRoutes[i].children.length >= 1) {
      temp = temp.concat(globalRoutes[i].children)
    }
  }
  return temp.length >= 1 ? fnCurrentRouteType(route, temp) : 'main'
}

/**
 * 添加动态(菜单)路由
 * @param {*} menuList 菜单列表
 * @param {*} routes 递归创建的动态(菜单)路由
 */
function fnAddDynamicMenuRoutes(menuList = [], routes = []) {
  var temp = []
  for (var i = 0; i < menuList.length; i++) {
    if (menuList[i].list && menuList[i].list.length >= 1) {
      temp = temp.concat(menuList[i].list)
    } else if (menuList[i].url && /\S/.test(menuList[i].url)) {
      menuList[i].url = menuList[i].url.replace(/^\//, '')
      var route = {
        path: menuList[i].url.replace('/', '-'),
        component: null,
        name: menuList[i].url.replace('/', '-'),
        meta: {
          menuId: menuList[i].menuId,
          title: menuList[i].name,
          isDynamic: true,
          isTab: true,
          iframeUrl: ''
        }
      }
      // url以http[s]://开头, 通过iframe展示
      if (isURL(menuList[i].url)) {
        route['path'] = `i-${menuList[i].menuId}`
        route['name'] = `i-${menuList[i].menuId}`
        route['meta']['iframeUrl'] = menuList[i].url
      } else {
        try {
          route['component'] = _import(`modules/${menuList[i].url}`) || null
        } catch (e) {
        }
      }
      routes.push(route)
    }
  }
  if (temp.length >= 1) {
    fnAddDynamicMenuRoutes(temp, routes)
  } else {
    mainRoutes.name = 'main-dynamic'
    mainRoutes.children = routes
    router.addRoutes([
      mainRoutes,
      {path: '*', redirect: {name: '404'}}
    ])
    sessionStorage.setItem('dynamicMenuRoutes', JSON.stringify(mainRoutes.children || '[]'))
    // // console.log('\n')
    // // console.log('%c!<-------------------- 动态(菜单)路由 s -------------------->', 'color:blue')
    // // console.log(mainRoutes.children)
    // // console.log('%c!<-------------------- 动态(菜单)路由 e -------------------->', 'color:blue')
  }
}

export default router
