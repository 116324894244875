import Vue from "vue";
let V = new Vue();
import $store from '@/store/index.js'
export default{
	isAuth: function(key){
		return JSON.parse(sessionStorage.getItem('permissions') || '[]').indexOf(key) !== -1 || false
	},
	setItem: function(key,value){
		// localStorage.setItem(key,JSON.stringify(value));
		return  new Promise(function(resolve, reject){
			sessionStorage.setItem(key,JSON.stringify(value));
			resolve(1);
		});
	},
	getItem: function(key){
		// let _value = localStorage.getItem(key);
		let _value = sessionStorage.getItem(key);
		if(_value == '' || _value == null || _value == undefined || _value == 'undefined'){
			return '';
		}else{
			if(typeof _value == 'string'){
				return JSON.parse(_value);
			}else{
				return '';
			}
		}
	},
	getMenuIndex: function(path){
		let _menuList =  this.getItem("menuList");
		let _menuIndex = 0;
		let _subMenuIndex = 0;
		let _returnFlg = true;
		_menuList.forEach((item,index) => {
			let _list = item.list;
			_list.forEach((subItem,sunIndex) => {
				if(subItem.routerName == path){
					_menuIndex = index;
					_subMenuIndex = sunIndex;
					_returnFlg = false;
					return false;
				}
				if(!_returnFlg){
					return false;
				}
			})
		})
		return [_menuIndex,_subMenuIndex];
	},
	// 文件转base64
	getBase64: function(file) {
		return new Promise((resolve, reject) => {
			let reader = new FileReader();
			let fileResult = "";
			reader.readAsDataURL(file.raw);
			//开始转
			reader.onload = function() {
				fileResult = reader.result;
			};
			//转 失败
			reader.onerror = function(error) {
				reject(error);
			};
			//转 结束 resolve 出去
			reader.onloadend = function() {
				resolve(fileResult);
			};
		});
	},
	// 金额添加千分位
	comdify: function (n) {
	    if(!n) return n;
		n += '';
		if (!n.includes('.')) n += '.';
		return n.replace(/(\d)(?=(\d{3})+\.)/g, function ($0, $1) {
			return $1 + ',';
		}).replace(/\.$/, '');

	    // let str = n.split('.');
	    // let re = /\d{1,3}(?=(\d{3})+$)/g;
	    // let n1 = str[0].replace(re, "$&,");
	    // return str.length > 1 && str[1] ? `${n1}.${str[1]}` : `${n1}.00`;
	},
	//去除千分位中的‘，’
	delcommafy: function (num){
	    if(!num) return num;
	    num = num.toString();
	    num = num.replace(/,/gi, '');
	    return num;
	},
	// 金额小写转大写
	getAmountOfMoney: function(n) {
		if (!/^(0|[1-9]\d*)(\.\d+)?$/.test(n)){
			return '';
		}else{
			let _unit = "千百拾亿千百拾万千百拾元角分";
			let _str = "";
			n += "00";
			var p = n.indexOf('.');
			if (p >= 0){
				n = n.substring(0, p) + n.substr(p+1, 2);
			}
			_unit = _unit.substr(_unit.length - n.length);
			for (var i=0; i < n.length; i++){
				_str += '零壹贰叁肆伍陆柒捌玖'.charAt(n.charAt(i)) + _unit.charAt(i);
			}
			return _str.replace(/零(千|百|拾|角)/g, "零").replace(/(零)+/g, "零").replace(/零(万|亿|元)/g, "$1").replace(/(亿)万|壹(拾)/g, "$1$2").replace(/^元零?|零分/g, "").replace(/元$/g, "元整");
		}
	},
	tableRowClassName({row, rowIndex}) {
		if (rowIndex % 2 !== 0) {
			return 'discolour-row';
		}
		return '';
	},
	tableScnenRowClassName({row, rowIndex}) {
		console.log(row.recType)
		if (row.recType === '1') {
			return 'scenechange-row';
		}
		if (row.recType === '3') {
			return 'sceneactual-row';
		}
		return '';
	},
	//遮罩层
	showLoading() {
		var sWidth = screen.availWidth;
		var sHeight = (screen.availHeight > document.body.scrollHeight) ? screen.availHeight : document.body.scrollHeight;

		// 创建遮罩背景
		var maskObj = document.createElement("div");
		maskObj.setAttribute('id', 'maskdiv');
		maskObj.style.position = "absolute";
		maskObj.style.top = "0";
		maskObj.style.left = "0";
		maskObj.style.backgroundColor = "rgba(128, 128, 128, 0.5)"; // 灰色半透明背景
		maskObj.style.width = sWidth + "px";
		maskObj.style.height = sHeight + "px";
		maskObj.style.zIndex = "10000";

		// 创建 loading 图标和文字容器
		var loadingContainer = document.createElement("div");
		loadingContainer.setAttribute('id', 'loadingContainer');
		loadingContainer.style.position = "fixed";
		loadingContainer.style.top = "50%";
		loadingContainer.style.left = "50%";
		loadingContainer.style.transform = "translate(-50%, -50%)";
		loadingContainer.style.backgroundColor = "#fff";
		loadingContainer.style.padding = "20px";
		loadingContainer.style.borderRadius = "10px";
		loadingContainer.style.boxShadow = "0 0 10px rgba(0, 0, 0, 0.5)";
		loadingContainer.style.textAlign = "center";

		// 创建 loading 图标
		var loadingIcon = document.createElement("img");
		//loadingIcon.src = "@Url.Content("~/Content/images/loading.gif")"; // 使用 Url.Content 生成绝对路径
		loadingIcon.src = "https://i.miji.bid/2023/11/28/d3172e065f40eefc779ec2f90d2dba7c.gif"; 
		loadingIcon.style.width = "30px";
		loadingIcon.style.height = "30px";

		// 创建文字
		var loadingText = document.createElement("div");
		loadingText.innerHTML = "文件读取中，请稍候...";
		loadingText.style.marginTop = "10px";
		loadingText.style.color = "black";

		// 将 loading 图标和文字添加到容器
		loadingContainer.appendChild(loadingIcon);
		loadingContainer.appendChild(loadingText);

		// 将容器添加到遮罩背景
		maskObj.appendChild(loadingContainer);

		// 将遮罩层添加到页面
		document.body.appendChild(maskObj);
	},
	//解除遮罩层
	loadingHide() {
		document.body.removeChild(document.getElementById('maskdiv'));
	},
	viewFile(fileUrl) {
		this.showLoading()
		$.ajax({
			url: $store.state.httpUrl + '/business/file/download?filePath=' + encodeURIComponent(fileUrl),
			type: 'post',
			dataType: 'blob',
			mimeType: 'text/plain; charset=x-user-defined',
			complete: (response) => {
				let rawLength = response['responseText'].length;
				if (rawLength == 0) {
					V.$notify.error({
						title: 'ERROR',
						message: '文件不存在!'
					});
					return;
				}
				this.loadingHide()
				let array = new Uint8Array(new ArrayBuffer(rawLength));
				for (let i = 0; i < rawLength; i++) {
					array[i] = response['responseText'].charCodeAt(i) & 0xff;
				}
				window.open("/pdfjs/web/viewer.html?file=" + this.getObjectURL(array, fileUrl));
			}
		});
	},
	// 将返回的流数据转换为url
	getObjectURL (file, filePath) {
		var suffixType = filePath.substring(filePath.lastIndexOf(".") + 1).toUpperCase();
		var type;
		if (suffixType === 'PDF') {
			type = 'application/pdf';
		} else if (suffixType === "DOC" || suffixType === "DOCX") {
			type = "application/msword";
		} else if (suffixType === "XLS" || suffixType === "XLSX") {
			type = "application/msexcel";
		} else {
			return
		}
		var binaryData = [];
		binaryData.push(file);
		let url = null;
		if (window.createObjectURL !== undefined) { // basic
			url = window.createObjectURL(new Blob(binaryData, {type: type}));
		} else if (window.webkitURL !== undefined) { // webkit or chrome
			try {
				url = window.webkitURL.createObjectURL(new Blob(binaryData, {type: type}));
			} catch (error) {
			}
		} else if (window.URL !== undefined) { // mozilla(firefox)
			try {
				url = window.URL.createObjectURL(new Blob(binaryData, {type: type}));
			} catch (error) {

			}
		}
		return url;
	},
}
