import $http from '@/utils/httpRequest.js'
import $common from '@/utils/common.js'
import Vue from 'vue';
let V = new Vue();
import $store from '@/store/index.js'

export default{
	// 获取用户信息
	getUserInfo: function(){
		return  new Promise(function(resolve, reject){
			$http({
				url: $store.state.httpUrl+'/sys/loginByOa',
				method: 'POST',
				data:{
					appId: $common.getItem("appId"),
					secretKey: $common.getItem("secretKey")
				}
			}).then((res) =>{
				if (res.data && res.data.resultCode === 200) {
					$common.setItem('ATOKEN',res.data.token);
					sessionStorage.setItem('token', res.data.token);
					$common.setItem('menuList',res.data.menuList);
					$common.setItem('permissions',res.data.permissions);
					// getSubjectDropDown();
					setMenuIndex(res.data.menuList);
					resolve(1);
				}else{
					V.$notify.error({
					  title: 'ERROR',
					  message: '用户信息不存在!'
					});
				}
			}).catch((res) => {
				V.$notify.error({
				  title: 'ERROR',
				  message: 'Request failed, please try again later!'
				});
			})
		});
	},
	getSubjectDropDown: function() {
		// // console.log($store.state.navBarSubjectList)
		// if ($store.state.navBarSubjectList === undefined){
			return  new Promise(function(resolve, reject){
				$http({
					url: $store.state.httpUrl+ "/common/getSubjectDropDown/" + $common.getItem("workNo"),
					method: 'get'
				}).then((res) =>{
					if (res.data && res.data.resultCode === 200) {
						$store.commit('common/updateSubjectList', res.data.body)
						if (res.data.body.length === 0){
							//$emit("setSubMenuIndex",this.getSubjectMenuIndex());
						} else {
							// if($store.state.selectSubjectNo == "" || $store.state.selectSubjectNo == undefined){
							if($common.getItem("subjectNo") == "" || $common.getItem("subjectNo") == undefined){
								$common.setItem("subjectNo", res.data.body[0].displayValue);
								$store.commit('common/updateSelectSubjectNo', res.data.body[0].displayValue)
							}else{
								let isExist = false;
								res.data.body.forEach(function (item,idx) {
									if (item.displayValue === $common.getItem("subjectNo")){
										isExist = true;
									}
								});
								if (!isExist){
									// this.subjectNo = this.subjectList[0].displayValue;
									$common.setItem("subjectNo", res.data.body[0].displayValue);
									$store.commit('common/updateSelectSubjectNo', res.data.body[0].displayValue)
								} else {
									$store.commit('common/updateSelectSubjectNo', $common.getItem("subjectNo"))
								}
							}
						}
						resolve(1);
					}
				}).catch((res) => {
					// console.log("ERROR:",res)
					V.$notify.error({
						title: 'ERROR',
						message: 'Request failed, please try again later!'
					});
				})
			});
		// } else {
		// 	return $store.state.navBarSubjectList
		// }
	}
}
function setMenuIndex(menuList){
	var _href = window.location.href;
	var _routerName = _href.substr(_href.indexOf("#/")+2);
	if(_href.indexOf("?") > 0){
		_routerName = _routerName.substr(0,_routerName.indexOf("?"));
	}
	let _index = 0;
	let _subIndex = 0;

	menuList.forEach((item,index)=>{
		item.list.forEach((subItem, subIndex) => {
			if(subItem.routerName == _routerName){
				_index = index;
				_subIndex = subIndex;
			}
		})
	})
	$common.setItem('menuIndex',_index);
	$common.setItem('subMenuIndex',_subIndex);
}

function getSubjectDropDown() {
	$http({
		url:  $store.state.httpUrl + "/common/getSubjectDropDown/" + $common.getItem("workNo"),
		method: "get",
		params: {},
	}).then((res) => {
		if (res.data && res.data.resultCode === 200) {
			$store.commit('common/updateSubjectList', res.data.body)
			if (!res.data.body){
				this.$emit("setSubMenuIndex",this.getSubjectMenuIndex());
			} else {
				if($store.state.selectSubjectNo == "" || $store.state.selectSubjectNo == undefined){
					$common.setItem("subjectNo", res.data.body[0].displayValue);
					$store.commit('common/updateSelectSubjectNo', res.data.body[0].displayValue)
				}else{
					let isExist = false;
					res.data.body.forEach(function (item,idx) {
						if (item.displayValue === $store.state.selectSubjectNo){
							isExist = true;
						}
					});
					if (!isExist){
						// this.subjectNo = this.subjectList[0].displayValue;
						$common.setItem("subjectNo", res.data.body[0].displayValue);
						$store.commit('common/updateSelectSubjectNo', res.data.body[0].displayValue)
					} else {
						$store.commit('common/updateSelectSubjectNo', $common.getItem("subjectNo"))
					}
				}
			}
		}
	});

}
