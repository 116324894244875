<template>
	<div id="app" class="region">
		<router-view />
	</div>
</template>


<script>
export default {
	name: 'app',
	mounted() {
		if (!!window.ActiveXObject || 'ActiveXObject' in window) {
			window.addEventListener('hashchange', () => {
				let currentPath = window.location.hash.slice(1);
				if (this.$route.path !== currentPath) {
					this.$router.push(currentPath);// 主动更改路由界面
				}
			}, false);
			window.addEventListener("popstate", function (e) {
				window.history.back();
			}, false);

		}
	}
}
</script>

<style lang="scss">
.loadingclass {
	.el-loading-spinner {
		i {
			color: #139cb6;
		}

		.el-loading-text {
			color: #023cfca9;
		}
	}
	z-index: 999999 !important;
}
</style>
